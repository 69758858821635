<template>
  <div class="joincircle">
    <div class="joincircle_center">
      <!-- <h1>加入的圈子</h1> -->
      <header>
        <p>加入的圈子</p>
      </header>
      <nav>
        <el-form ref="resourceRef" :model="resourceForm" :inline="true">
          <el-form-item label="教研圈名称" prop="circleName">
            <el-input v-model="resourceForm.circleName" @change="querylist" size="mini" clearable></el-input>
          </el-form-item>
          <el-form-item label="学科类型" prop="subjectTypeId">
            <el-select v-model="resourceForm.subjectTypeId" @change="changeshuject" size="mini" clearable>
              <el-option v-for="item in subjectypedata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学科" prop="subjectId">
            <el-select v-model="resourceForm.subjectId" size="mini" clearable>
              <el-option v-for="item in subjectdata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学段" prop="periodId">
            <el-select v-model="resourceForm.periodId" @change="changeperiod" size="mini" clearable>
              <el-option v-for="item in perioddata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="年级" prop="gradeId">
            <el-select v-model="resourceForm.gradeId" size="mini" clearable>
              <el-option v-for="item in gradedata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-form-item prop="startTime" style="width:150px">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="resourceForm.startTime"
                style="width: 100%;"
                size="mini"
                clearable
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
            <el-form-item prop="endTime" style="width:150px">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="resourceForm.endTime"
                style="width: 100%;"
                size="mini"
                clearable
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-form-item>
          <!-- <el-form-item label="审核状态" prop="auditStatus" v-if="this.activeName=='first'">
            <el-select v-model="resourceForm.auditStatus" placeholder="请选择活动区域">
              <el-option v-for="item in auditdata" :key="item.id" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-form-item>-->
        </el-form>
        <div class="nav_btns">
          <el-button type="primary" @click="querylist" size="mini">查询</el-button>
          <el-button @click="resetfrom('resourceRef')" size="mini">重置</el-button>
        </div>
      </nav>
      <main>
        <el-table :data="resTabDatas" stripe style="width: 100%" height="500">
          <!-- <el-table-column type="selection" width="40" align="center" fixed="left"></el-table-column> -->
          <el-table-column type="index" width="50" align="center" fixed="left" label="序号" :index="computeTableIndex"></el-table-column>
          <el-table-column prop="circleName" align="center" label="教研圈名称" width="200" fixed="left" show-overflow-tooltip></el-table-column>
          <el-table-column prop="periodName" label="学段" width="150" show-overflow-tooltip></el-table-column>

          <el-table-column prop="gradeName" label="年级" width="150" show-overflow-tooltip></el-table-column>
          <el-table-column prop="subjectTypeName" label="学科类型" width="150" show-overflow-tooltip></el-table-column>

          <el-table-column prop="subjectName" label="学科" width="150" show-overflow-tooltip></el-table-column>
          <!-- <el-table-column prop="resourceType" label="资源类型" width="120"></el-table-column> -->
          <el-table-column prop="createUser" label="圈子创建人" width="150" show-overflow-tooltip></el-table-column>
          <el-table-column prop="memberNo" align="center" label="圈子成员数量" width="120" show-overflow-tooltip></el-table-column>
          <!-- <el-table-column prop="lastAuditMessage" label="审核意见" width="300" show-overflow-tooltip></el-table-column> -->
          <el-table-column prop="createTime" label="创建时间" width="200" show-overflow-tooltip></el-table-column>
          <!-- <el-table-column prop="createTime" label="提交审核时间" width="200"></el-table-column> -->
          <el-table-column fixed="right" label="操作" width="300">
            <template slot-scope="scope">
              <!-- <el-button
                type="success"
                size="mini"
                @click="submitdata(scope.row.id,1)"
                :disabled="scope.row.auditStatus=='审核通过'||scope.row.auditStatus=='审核不通过'"
              >通过</el-button>-->
              <!-- <el-button type="danger" size="mini" @click="submitdata(scope.row.id,0)">不通过</el-button> -->
              <el-button type="text" size="mini" @click="viewdata(scope.row)" style="color: #EB5757;">查看教研圈</el-button>
              <el-button type="text" size="mini" @click="listdata(scope.row)">话题帖子管理</el-button>

              <!-- <el-button size="mini" type="success" @click="ifagree(scope.row.id,1)" :disabled="scope.row.auditStatus=='审核通过'">通过</el-button>
              <el-button size="mini" type="danger" @click="ifagree(scope.row.id,0)">驳回</el-button>
              <el-button size="mini" type="warning" @click="checkClass(scope.row.auditMessage)">查看</el-button>
              <el-button size="mini" type="info" @click="listdata(scope.row)">记录</el-button>-->
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="curpage"
          :page-sizes="[10, 20, 30, 40, 50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next"
          :total="total"
        ></el-pagination>
      </main>
      <!--  -->
      <el-dialog
        :title="'话题帖子管理-'+this.tiezidata.circleName"
        :visible.sync="tieziDialog"
        width="50%"
        top="4vh"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="handleClose"
      >
        <mems :memsdata="tiezidata" :replytiezi="replytiezi"></mems>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import mems from "./component/component/memsdig.vue";
export default {
  data() {
    return {
      // 帖子管理
      tieziDialog: false,
      tiezidata: {},
      replytiezi: false,
      //
      // 加入圈子的列表
      resourceForm: {
        circleName: null,
        subjectTypeId: null,
        subjectId: null,
        periodId: null,
        gradeId: null,
        startTime: null,
        endTime: null,
        auditStatus: null,
      },
      //学科类型
      subjectypedata: null,
      // 学科
      subjectdata: null,
      // 学段
      perioddata: null,
      // 年级
      gradedata: null,

      // 表格数据
      resTabData: null,
      // 分页
      curpage: 1, //当前页数
      pageSize: 10, //当前条数
      total: 100, //总数
    };
  },
  created() {
    this.querySubjectType(); //获取课程类型
    this.querySubject(0); //获取学科信息
    this.queryPeriod(); //获取学段
    this.queryGrade(0); //获取年级信息
    // 获取教研圈列表
    this.queryMyNoOnlineCircle();
  },
  methods: {
    //关闭回调
    handleClose(done) {
      console.log("关闭前的回调", done);
      this.$confirm("确认关闭？")
        .then((_) => {
          this.replytiezi = true;
          done();
        })
        .catch((_) => {});
    },
    // 加入的圈子-查询圈子
    async queryMyNoOnlineCircle() {
      console.log(this.resourceForm, "resourceForm");
      const { data, status } = await this.$Http.queryMyEnterCircle({
        // circleName  : circleName , //圈子名称
        // subjectTypeId: subjectTypeId, //课程类型ID
        // subjectId: subjectId, //学科ID
        // periodId: periodId, //学段ID
        // gradeId: gradeId, //年级ID
        // startTime: startTime, //审核开始时间
        // endTime :endTime,//结束时间
        // auditStatus : auditStatus , //审核状态
        //
        ...this.resourceForm,
        pageNo: this.curpage, //页码
        pageSize: this.pageSize, //每页数量
      });
      if (status == 200) {
        console.log(data, status, "获取加入的圈子-查询圈子");
        this.resTabData = data;
        // this.$refs["resourceRef"].resetFields();
      }
    },
    // 获取课程类型
    async querySubjectType() {
      await this.$Http.querySubjectType().then((res) => {
        console.log(res, "获取课程类型");
        if (res.status == 200) {
          this.subjectypedata = res.data;
        }
      });
    },
    // 获取学科信息
    async querySubject(id) {
      console.log(id);
      let ids = [];
      if (id !== 0) {
        ids.push(id);
      }
      const { data, status } = await this.$Http.querySubject({
        idList: ids,
      });
      if (status == 200) {
        console.log(data, status, "获取学科");
        this.subjectdata = data;
      }
    },
    // 获取学段
    async queryPeriod() {
      await this.$Http.queryPeriod().then((res) => {
        console.log(res, "获取学段");
        if (res.status == 200) {
          this.perioddata = res.data;
        }
      });
    },
    // 获取年级信息
    async queryGrade(id) {
      console.log(id);
      let ids = [];
      if (id !== 0) {
        ids.push(id);
      }
      const { data, status } = await this.$Http.queryGrade({
        idList: ids,
      });
      if (status == 200) {
        console.log(data, status, "获取年级信息");
        this.gradedata = data;
      }
    },
    // 学科类型的值得变化
    changeshuject(val) {
      if (!val) {
        this.querySubject(0);
      }
      this.querySubject(val);
    },
    // 学段的值得变化
    changeperiod(val) {
      if (!val) {
        this.queryGrade(0);
      }
      this.queryGrade(val);
    },
    // 查询
    querylist() {
      console.log(this.activeName);
      this.queryMyNoOnlineCircle();
      this.querySubject(0); //获取学科信息
      this.queryGrade(0); //获取年级信息
    },
    listdata(row) {
      console.log(row, "帖子管理");
      this.tiezidata = row;
      this.tieziDialog = true;
    },
    // 重置
    resetfrom(formName) {
      console.log(this.$refs, "重置");
      this.$refs[formName].resetFields();
      this.queryMyNoOnlineCircle();
    },

    // --
    viewdata(row) {
      console.log(row);
      this.$router.push({
        path: "/circleintroduce",
        query: {
          introId: row.id,
          circlesite: 0,
        },
      });
    },

    //监听index
    computeTableIndex(index) {
      // console.log(index, this.queryInfo.pagenum, this.queryInfo.pagesize, 'e')
      return (this.curpage - 1) * this.pageSize + index + 1;
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.queryMyNoOnlineCircle();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.curpage = val;
      this.queryMyNoOnlineCircle();
    },
  },
  computed: {
    resTabDatas() {
      console.log(this.resTabData, "this.resTabData");
      let a = this.resTabData;
      if (a !== null) {
        let aa = this.resTabData.list;
        this.total = this.resTabData.total;
        if (aa[0].periodName.join === undefined) {
          return aa;
        }
        for (let x = 0; x < aa.length; x++) {
          aa[x].periodName = aa[x].periodName.join("/");
          aa[x].gradeName = aa[x].gradeName.join("/");
          // aa[x].subjectName = aa[x].subjectName.join("/");
          // aa[x].subjectType = aa[x].subjectType.join("/");
        }
        return aa;
      }
    },
  },
  components: {
    mems,
  },
};
</script>
<style lang="scss" scoped>
.joincircle {
  .joincircle_center {
    header {
      height: 70px;
      padding: 0 20px;
      background-color: #fff;
      border-bottom: 1px solid #ebebeb;
      p {
        // width: 108px;
        // height: 24px;
        height: 70px;
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 600;
        color: #141516;
        line-height: 70px;
      }
    }
    nav {
      background-color: #fff;
      padding: 15px;
      // .el-form-item{
      //   width: 230px;
      // }
      .el-input {
        width: 150px;
      }
      .el-select {
        width: 120px;
      }
      .nav_btns {
        display: flex;
        justify-content: flex-end;
      }
    }
    main {
      margin-top: 10px;
      padding: 0 20px;
      background-color: #fff;
      .el-pagination {
        display: flex;
        justify-content: center;
        padding: 20px 0;
      }
    }
  }
}
</style>